import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime, map, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "src/app/data/auth/auth.service";
import Swal from "sweetalert2";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  MENUITEMS: Menu[] = [];
  result: any;
  rol_id:any;
 /* MENUITEMS: Menu[] = [
    {
      headTitle1: "General",
    },
    {
      path: "/dashboard",
      title: "Inicio",
      icon: "home",
      type: "link",
      active: true,
    },
    {
      title: "Bandeja",
      icon: "sample-page",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/try-documents", title: "Bandeja de Entrada", type: "link" },
        { path: "/request-unarchive", title: "Solicitud de Desarchivo", type: "link" },
      ],
    },
    {
      path: "/derivation/document-type",
      title: "Generar Nuevo Documento",
      icon: "sample-page",
      type: "link",
    },
    {
      path: "/ventanilla",
      title: "Ventanilla Única",
      icon: "sample-page",
      type: "link",
    },
    {
      title: "Documentos",
      icon: "sample-page",
      type: "sub",
      badgeType: "light-primary",
      children: [
        {
          path: "/document/my-documents",
          title: "Mis Documentos",
          type: "link",
        },
        { path: "/document/my-files", title: "Mis Archivos", type: "link" },
      ],
    },
    {
      path: "/follow-ups",
      title: "Seguimiento",
      icon: "sample-page",
      type: "link",
    },
    {
      title: "Administración",
      icon: "sample-page",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/administration", title: "Usuarios", type: "link" },
        { path: "/flow-tree", title: "Organigrama", type: "link" },
        { path: "/charges", title: "Cargos", type: "link" },
      ],
    },
    {
      title: "Corte Administrativo",
      icon: "sample-page",
      type: "sub",
      badgeType: "light-primary",
      children: [
        {
          path: "/administrative-court/single",
          title: "Informe Individual",
          type: "link",
        },
        {
          path: "/administrative-court/boosess",
          title: "Jefes Inmediatos",
          type: "link",
        },
        {
          path: "/administrative-court/presentation",
          title: "Lista de Presentación",
          type: "link",
        },
        {
          path: "/administrative-court/secretary",
          title: "Reporte Secretaria",
          type: "link",
        },
      ],
    },
  ];
*/
  // Array
  //items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  items = this.loadMenu();
  constructor(
    private router: Router,		
    private authService : AuthService,
  ) {
    console.log('inicio');
    
   // items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  getUser(){ 
    this.authService.checkToken()
    .subscribe(res=>{
      if( res.statusCode < 400 ){
    		console.log('res.data =>', res.data);
        this.result = res.data;
        this.rol_id = res.data.roles[0].rol.id;
        console.log('este es el rol_id =>',this.rol_id);
        console.log('this.result',this.result);
        //ADMINISTRADOR
        if(this.rol_id == 1){
          console.log('entra por verdad');
          this.MENUITEMS.push(
            {
              path: "/dashboard",
              title: "Inicio",
              icon: "home",
              type: "link",
              active: true,
            },
            {
              title: "Bandeja",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                { path: "/try-documents", title: "Bandeja de Entrada", type: "link" },
                { path: "/try-documents/outbox", title: "Bandeja de Salida", type: "link" },
                { path: "/request-unarchive", title: "Solicitud de Desarchivo", type: "link" },
                { path: "/try-documents/request", title: "Solicitud de Modificación", type: "link" },
              ],
            },
            {
              path: "/derivation/document-type",
              title: "Generar Nuevo Documento",
              icon: "sample-page",
              type: "link",
            },
            {
              path: "/ventanilla",
              title: "Ventanilla Única",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Documentos",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/document/my-documents",
                  title: "Mis Documentos",
                  type: "link",
                },
                { path: "/document/my-files", title: "Mis Archivos", type: "link" },
              ],
            },
            {
              path: "/follow-ups",
              title: "Seguimiento",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Administración",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                { path: "/administration", title: "Usuarios", type: "link" },
                { path: "/flow-tree", title: "Organigrama", type: "link" },
                { path: "/charges", title: "Cargos", type: "link" },
                { path: "/type_documents", title: "Tipos de Documentos", type: "link" },
              ],
            },
            
            {
              title: "Corte Administrativo",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/administrative-court/single",
                  title: "Informe Individual",
                  type: "link",
                },
                {
                  path: "/administrative-court/boosess",
                  title: "Jefes Inmediatos",
                  type: "link",
                },
                {
                  path: "/administrative-court/presentation",
                  title: "Lista de Presentación",
                  type: "link",
                },
                
               
              ],
            },
            {
              title: "Reportes",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/administrative-court/secretary",
                  title: "Reporte Secretaria",
                  type: "link",
                },
                {
                  path: "/report-derivation",
                  title: "Gestión Documental",
                  type: "link",
                },
                {
                  path: "report-hr/single-window",
                  title: "Documentos PIE/PLC/MC",
                  type: "link",
                },
              ]
            },
          );
        }
        //VENTANILLA
        if(this.rol_id == 2){
          this.MENUITEMS.push(
            {
              path: "/dashboard",
              title: "Inicio",
              icon: "home",
              type: "link",
              active: true,
            },
            {
              title: "Bandeja",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                { path: "/try-documents", title: "Bandeja de Entrada", type: "link" },
                { path: "/try-documents/outbox", title: "Bandeja de Salida", type: "link" },
              ],
            },
            {
              path: "/derivation/document-type",
              title: "Generar Nuevo Documento",
              icon: "sample-page",
              type: "link",
            },
            {
              path: "/ventanilla",
              title: "Ventanilla Única",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Documentos",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/document/my-documents",
                  title: "Mis Documentos",
                  type: "link",
                },
                { path: "/document/my-files", title: "Mis Archivos", type: "link" },
              ],
            },
            {
              path: "/follow-ups",
              title: "Seguimiento",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Corte Administrativo",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/administrative-court/single",
                  title: "Informe Individual",
                  type: "link",
                },
                {
                  path: "/administrative-court/secretary",
                  title: "Reporte Secretaria",
                  type: "link",
                },
                {
                  path: "/report-derivation",
                  title: "Reporte de Gestión Documental",
                  type: "link",
                },
              ],
            },
          );
        }
        //SEGUIMIENTO
        if(this.rol_id == 3){
          this.MENUITEMS.push(
            {
              path: "/dashboard",
              title: "Inicio",
              icon: "home",
              type: "link",
              active: true,
            },
            {
              path: "/follow-ups",
              title: "Seguimiento",
              icon: "sample-page",
              type: "link",
            },
          )
        }
        //USUARIO GENERAL
        if(this.rol_id == 4){
          this.MENUITEMS.push(
            {
              path: "/dashboard",
              title: "Inicio",
              icon: "home",
              type: "link",
              active: true,
            },
            {
              title: "Bandeja",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                { path: "/try-documents", title: "Bandeja de Entrada", type: "link" },
                { path: "/try-documents/outbox", title: "Bandeja de Salida", type: "link" },
              ],
            },
            {
              path: "/derivation/document-type",
              title: "Generar Nuevo Documento",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Documentos",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/document/my-documents",
                  title: "Mis Documentos",
                  type: "link",
                },
                { path: "/document/my-files", title: "Mis Archivos", type: "link" },
              ],
            },
            {
              path: "/follow-ups",
              title: "Seguimiento",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Corte Administrativo",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/administrative-court/single",
                  title: "Informe Individual",
                  type: "link",
                },
                {
                  path: "/report-derivation",
                  title: "Reporte de Gestión Documental",
                  type: "link",
                },
              ],
            },
          );
        }
        //AUTORIDADES
        if(this.rol_id == 5){
          this.MENUITEMS.push(
            {
              path: "/dashboard",
              title: "Inicio",
              icon: "home",
              type: "link",
              active: true,
            },
            {
              title: "Bandeja",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                { path: "/try-documents", title: "Bandeja de Entrada", type: "link" },
                { path: "/try-documents/outbox", title: "Bandeja de Salida", type: "link" },
                { path: "/request-unarchive", title: "Solicitud de Desarchivo", type: "link" },
              ],
            },
            {
              path: "/derivation/document-type",
              title: "Generar Nuevo Documento",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Documentos",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/document/my-documents",
                  title: "Mis Documentos",
                  type: "link",
                },
                { path: "/document/my-files", title: "Mis Archivos", type: "link" },
              ],
            },
            {
              path: "/follow-ups",
              title: "Seguimiento",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Corte Administrativo",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/administrative-court/single",
                  title: "Informe Individual",
                  type: "link",
                },
                {
                  path: "/administrative-court/boosess",
                  title: "Jefes Inmediatos",
                  type: "link",
                },
                {
                  path: "/administrative-court/presentation",
                  title: "Lista de Presentación",
                  type: "link",
                },
                {
                  path: "/administrative-court/secretary",
                  title: "Reporte Secretaria",
                  type: "link",
                },
                {
                  path: "/report-derivation",
                  title: "Reporte de Gestión Documental",
                  type: "link",
                },
              ],
            },
          );
        }

        //SECRETARIA
        if(this.rol_id == 6){
          this.MENUITEMS.push(
            {
              path: "/dashboard",
              title: "Inicio",
              icon: "home",
              type: "link",
              active: true,
            },
            {
              title: "Bandeja",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                { path: "/try-documents", title: "Bandeja de Entrada", type: "link" },
                { path: "/try-documents/outbox", title: "Bandeja de Salida", type: "link" },


              ],
            },
            {
              path: "/derivation/document-type",
              title: "Generar Nuevo Documento",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Documentos",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/document/my-documents",
                  title: "Mis Documentos",
                  type: "link",
                },
                { path: "/document/my-files", title: "Mis Archivos", type: "link" },
              ],
            },
            {
              path: "/follow-ups",
              title: "Seguimiento",
              icon: "sample-page",
              type: "link",
            },
            {
              title: "Corte Administrativo",
              icon: "sample-page",
              type: "sub",
              badgeType: "light-primary",
              children: [
                {
                  path: "/administrative-court/single",
                  title: "Informe Individual",
                  type: "link",
                },
                {
                  path: "/administrative-court/secretary",
                  title: "Reporte Secretaria",
                  type: "link",
                },
                {
                  path: "/report-derivation",
                  title: "Reporte de Gestión Documental",
                  type: "link",
                },
              ],
            },
          );
        }

      }else{
        Swal.fire('Atención', 'Credenciales Incorrectas', 'error');
      }
    })
  }

  loadMenu(){
    //CARGADO DEL MENU EN BASE AL USUARIO
    //CARGANDO LOS USUARIOS
    this.getUser();
    
    
    return new BehaviorSubject<Menu[]>(this.MENUITEMS);
    /*return this.loginService.checkToken()
      .pipe(
        map( () =>
        )
      )
    */
  }
}
