import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-report-pdf',
  templateUrl: './report-pdf.component.html',
  styleUrls: ['./report-pdf.component.scss']
})
export class ReportPdfComponent {
  @Input() public datos;
  documentReport: any;
  private pdf: PDFDocumentProxy;
  src = '';
  isPdfLoaded = false;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ){
    
  }
  ngOnInit(): void {
    console.log('este es el inicio');
    console.log('roadmap', this.datos);
    setTimeout(() => {
      //this.modalService.open(content, { centered: true, size: "xl" });
      const doc = new jsPDF('p', 'pt', 'letter');
      this.documentReport = document.getElementById('htmlData');
      let content2 = this.documentReport;
      doc.html(
        content2, {
        callback:  (doc) => {
          var pdfBase64 = doc.output('datauristring');
          this.src = pdfBase64;
          console.log('this.src',this.src);
        },
        x: 20,
        y: 10,
      });
    }, 300);
  }

  closeModal(){
    //this.modalService.dismissAll();
    this.activeModal.close();
  }

  onLoaded(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.isPdfLoaded = true;
  }


  printPasswordPdf(){
    this.pdf.getData().then((u8) => {
      let blob = new Blob([u8.buffer], {
          type: 'application/pdf'
      });

      const blobUrl = window.URL.createObjectURL((blob));
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
  });
  }
}
