<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img
            class="img-fluid for-light"
            src="assets/images/logo.png"
            alt=""
          />
          <img class="img-fluid for-dark" src="assets/images/logo.png" alt="" />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons
          [icon]="'align-center'"
          class="status_toggle middle"
        ></app-feather-icons>
      </div>
    </div>
    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <app-swiper></app-swiper>
    </div>
    <div
      class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto"
    >
      <ul class="nav-menus">
        <!--li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li-->

        <li class="onhover-dropdown">
          <div class="notification-box" (click)="toggleInbox()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#fill-file"></use>
            </svg>
            <span class="badge rounded-pill badge-secondary">{{
              authService.countDerivated
            }}</span>
          </div>
        </li>

        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="onhover-dropdown" *ngIf="authService.positions.length > 1">
          <app-position-type></app-position-type>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
