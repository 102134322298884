
<div class="container-fluid">
    <br>
    <br>
    <div class="row " style="display: flex; justify-content: center;">
   
        <div class="col-xl-9 col-md-9 box-col-9" *ngIf="roadmaps.length > 0">
            <div class="card" *ngFor="let roadmap of roadmaps; let i=index">
              <div class="card-header header-height-c header-primary">
                <h5 class="text-white">Hoja de Ruta: {{roadmap.roadMap.type}}-{{roadmap.roadMap.nuri}}/{{roadmap.roadMap.gestion}}</h5>
              </div>
      
              <div class="card-body">
                <!-- <h5>HR {{roadmap.roadMap.nuri}}</h5> -->
                <div class="row details m-b-15 mb-0 mt-0 pt-1">
                  <div class="col-5 mb-0 mt-0 pt-1"><span><strong>Remitente:</strong></span> {{roadmap.userFrom.names}} {{roadmap.userFrom.father_last_name}} {{roadmap.userFrom.mother_last_name}}</div>
                  <div class="col-4 mb-0 mt-0 pt-1"><span><strong>Fecha Creación Doc.:</strong>  </span> {{roadmap.roadMap.created_at | date: 'dd/MM/yyyy'}} {{roadmap.roadMap.created_at | date: 'H:mm:ss'}}</div>
                  <!-- <div class="col-3 mb-0 mt-0 pt-1"><span><strong>Tipo Documento:</strong>  </span> {{roadmap.document.document_type.name}}</div> -->
                </div>
                <hr>
                <div class="row details m-b-15 mb-0 mt-0 pt-1">
                  <div class="col-5 mb-0 mt-0 pt-1"><span><strong>CITE Inicio:</strong></span> {{external.cite}}</div>
                  <div class="col-7 mb-0 mt-0 pt-1"><span><strong>Referencia:</strong>  </span> {{external.reference}}</div>
                </div>
                <!--div class="row details m-b-15">
                  <div class="col-2 text-bold"><span>Referencia </span></div>
                  <div class="col-2">{{roadmap.document.reference}} </div>
                  <div class="col-2 text-bold"><span>Documento</span></div>
                  <div class="col-2">{{roadmap.document.document_type.type}}</div>
                  <div class="col-2 text-bold"><span>Tipo </span></div>
                  <div class="col-2">{{roadmap.document.document_type.name}}</div>
                </div-->
                
                <!--app-color-palette></app-color-palette-->
      
                <div class="text-center m-b--35">
                  <i class="icofont icofont-curved-down t-30 cursor" id="icon-down-{{i}}" (click)="listChildren(i, roadmap.roadMap.id)"></i>
                </div>
      
                <div *ngIf="showListItemsD && indexFather == i">
                  <hr class="m-t-30">
                  <div class="table-responsive signal-table m-t-15" id="table-derivation">
                    <table class="table table-fit">
                      <thead class="color-head-min">
                        <tr>
                          <!-- <th></th> -->
                          <!-- <th>No.</th> -->
                          <th>Remitente</th>
                          <th>Destinatario</th>
                          <th>Fecha Remitente</th>
                          <th>Fecha Destinatario</th>
                          <!-- <th>Dias</th>
                          <th>Adj.</th>
                          <th>Estado</th> -->
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="loading">
                          <td class="text-center" colspan="8">
                            <app-reload></app-reload>
                          </td>
                        </tr> -->
                        <tr *ngFor="let child of roadmapChildren; let index=index" id="item-derivation-{{index}}" 
                        [ngClass]="
                          child.derived_type == 'ORIGINAL' ? 
                            child.stateDerivation.id == 2 ? 
                              'color-warning' 
                              : child.stateDerivation.id == 6 ? 
                                'color-rojo' 
                                : 'color-original' 
                          : child.derived_type == 'COPIA' ? 
                              child.stateDerivation.id == 2 ? 
                                  'color-warning' 
                                  : child.stateDerivation.id == 6 ? 
                                    'color-rojo' 
                                    : 'color-cream' 
                          : ''"
                          >
                          <!-- <td>
                            <i class="icofont icofont-plus-circle cursor t-20" id="icon-plus-{{index}}" (click)="expandedRow( index, child )"></i> 
                          </td>
                          <td>{{index+1}}</td> -->
                          <td>
                            <span>{{child.userFrom.names}} {{child.userFrom.father_last_name}} {{child.userFrom.mother_last_name}}</span>
                            <p class="t-12"><span class="badge badge-light color-grey">{{child.positionTypeFrom.name}}</span></p>
                          </td>
                          <td>
                            {{child.userTo.names}} {{child.userTo.father_last_name}} {{child.userTo.mother_last_name}}
                            <p class="t-12"><span class="badge badge-light color-grey">{{child.positionTypeTo.name}}</span></p>
                          </td>
                          <td>{{child.date_derivation | date: 'yyyy/MM/dd'}} {{child.date_derivation | date: 'H:mm:ss'}}</td>
                          <td>{{child.date_reception | date: 'yyyy/MM/dd'}} {{child.date_reception | date: 'H:mm:ss'}}</td>
                          <!-- <td>{{child.dias}}</td>
                          <td>{{child.derived_type}}</td>
                          <td>
                            
                            <app-state-derivation [id]="child.stateDerivation.id" [name]="child.stateDerivation.name"></app-state-derivation>
      
                            <img src="assets/images/location-doc.png" alt="" class="w-47" *ngIf="roadmapChildren.length-1 == index">
                            <p>
                              <span class="badge state-doc-add" *ngIf="child.stateDerivation.id == 10">{{child.stateDerivation.name}}</span>
                            </p>
                          </td> -->
                          <!-- <td>
                          </td> -->
                        </tr>
                        <!-- <tr class="text-center">
                          <td colspan="10">
                            <img src="assets/images/location-doc.png" alt="">
                          </td>
                        </tr>                   -->
                      </tbody>
                    </table>
                  </div>
                </div>
      
              </div>
            </div>
          </div>

    </div>

</div>