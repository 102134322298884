import { Component } from '@angular/core';

@Component({
  selector: 'app-report-hr',
  templateUrl: './report-hr.component.html',
  styleUrls: ['./report-hr.component.scss']
})
export class ReportHrComponent {

}
