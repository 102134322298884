import { Injectable } from '@angular/core';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private encryptService: EncryptService,
  ) { }

  saveToken( token: string ){
    localStorage.setItem('token', token);
  }

  getToken(){
    return localStorage.getItem('token');
  }

  removeToken(){
    localStorage.removeItem('token');
  }

  removeUserPosition(){
    localStorage.removeItem('position');
  }

  saveUserDetail( user: any ){
    console.log('user',user)
    let positionDetalle: any = {
      positionTypeId: user.position_type.id,
      namePosition: user.position_type.name,
      dependenceId: user.dependence.id,
      nameDependence: user.dependence.name
    };

    let userEncrypted = this.encryptService.encrypt( positionDetalle );
    localStorage.setItem( 'position', userEncrypted );

  }

  getUserDetail(){

    if( !localStorage.getItem('position') ){
      // this.authService.logout();
      return false;
    }

    const userEncrypted = localStorage.getItem('position');
    const userDecrypted = this.encryptService.decrypt( userEncrypted );
    return JSON.parse(userDecrypted);
      
  }

}
