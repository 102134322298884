import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/data/auth/auth.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  public openNotification: boolean = false;

  constructor(public router: Router, public authService: AuthService) {}

  ngOnInit() {}

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
    this.router.navigate(["/try-documents"]);
  }
}
