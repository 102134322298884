import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/data/auth/auth.service';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent implements OnInit {

  position: string = '';

  constructor(
    public authService: AuthService
  ){  }

  ngOnInit(): void {

    this.authService.positionDefault$
      .subscribe( res =>{
        if( res == undefined ){
          this.authService.positionSelected$.subscribe( res => this.position = res.namePosition );
        }else{
          this.position = res.position_type.name;
        }
      })

  }

}
