import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../../../../environments/environment";

import { AuthService } from "src/app/data/auth/auth.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  pathAvatar: string = "";

  constructor(public router: Router, public authService: AuthService) {}

  ngOnInit() {
    this.pathAvatar = `${environment.PATH_IMAGE}/${this.authService.imagen}`;
  }

  logout() {
    this.authService.logout();
  }
}
