import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../shared/services/alert.service';
import { AuthService } from '../../../data/auth/auth.service';
import Swal from 'sweetalert2';
import { TokenService } from '../../shared/services/token.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  form: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private responseService: AlertService,
    private tokenService: TokenService  
  ) { }

  ngOnInit() {

    if( this.tokenService.getToken() ) this.router.navigate(['/dashboard']);
    this.formInit();

  }

  formInit(){

    this.form = this.fb.group({
      username: new FormControl('', [
        Validators.required
      ]), 
      password: new FormControl('', [
        Validators.required
      ])
    })

  }

  singIn(){

    this.authService.signIn( this.form.value )
      .subscribe( res => {
        if( res.statusCode < 400 ){

          this.responseService.welcome();

          this.router.navigate(['/dashboard']);

        }else{
          Swal.fire('Atención', 'Credenciales Incorrectas', 'error');
        }
      })

  }

}
