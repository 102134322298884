import { environment } from './../../../environments/environment';
import { ResponseAPI } from './../../domain/models/response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ConsultService {

    private readonly URL = environment.API_URL;

    constructor(
        private http: HttpClient
    ){}

    findRoadmap(payload:any ): Observable<ResponseAPI>{
        return this.http.post<ResponseAPI>(`${this.URL}/consult-roadmap/get-roadmap`,payload)
    }

    findByRoadmap( id: number ): Observable<ResponseAPI>{
        return this.http.get<ResponseAPI>(`${this.URL}/consult-roadmap/${id}`);
      }
    

    encodeRoadMap(payload:any ): Observable<ResponseAPI>{
        return this.http.post<ResponseAPI>(`${this.URL}/consult-roadmap/encrypt`,payload)
    }
}