import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { ResponseAPI } from '../../domain/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  private readonly URL = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }

  create( payload: any ): Observable<ResponseAPI>{
    return this.http.post<ResponseAPI>(`${this.URL}/external`, payload);
  }

  findAll(): Observable<ResponseAPI>{
    return this.http.get<ResponseAPI>(`${this.URL}/external`);
  }

  findAllDerivations(): Observable<ResponseAPI>{
    return this.http.get<ResponseAPI>(`${this.URL}/external/derivations`);
  }

  getByRequestId(id:number): Observable<ResponseAPI>{
    return this.http.get<ResponseAPI>(`${this.URL}/external/request/${id}`);
  }

  getAllRequest(): Observable<ResponseAPI>{
    return this.http.get<ResponseAPI>(`${this.URL}/external/request`);
  }


  getAllByType(payload:any): Observable<ResponseAPI>{
    return this.http.post<ResponseAPI>(`${this.URL}/external/type`,payload);
  }

  update( id:number,payload:any ): Observable<ResponseAPI>{
    return this.http.put<ResponseAPI>(`${this.URL}/external/${id}`, payload);
  }

  storeDerivationExternal( payload: any): Observable<ResponseAPI>{
    console.log('entra al puente');
    return this.http.post<ResponseAPI>(`${this.URL}/derivation/external`, payload);
  }

  updateExternal( payload: number): Observable<ResponseAPI>{
    return this.http.post<ResponseAPI>(`${this.URL}/derivation/external-update`, payload);
  }

  storeRequestModify( payload: any): Observable<ResponseAPI>{
    return this.http.post<ResponseAPI>(`${this.URL}/external/request`, payload);
  }

  
  updatePositionUserExternal( id:number, payload: number): Observable<ResponseAPI>{
    return this.http.put<ResponseAPI>(`${this.URL}/external-user/${id}`, payload);
  }

  approveRequest( payload: any): Observable<ResponseAPI>{
    return this.http.post<ResponseAPI>(`${this.URL}/external/approve-request`, payload);
  }

  reproveRequest( payload: any): Observable<ResponseAPI>{
    return this.http.post<ResponseAPI>(`${this.URL}/external/reprove-request`, payload);
  }
  

  
  



}
