import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportRoutingModule } from './report-routing.module';
import { SingleWindowComponent } from './pages/single-window/single-window.component';
import { SharedModule } from "../../shared/shared.module";
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReportPdfComponent } from './components/report-pdf/report-pdf.component';

@NgModule({
  declarations: [
    SingleWindowComponent,
    ReportPdfComponent
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    SharedModule,
    NgSelectModule,
    PdfViewerModule
]
})
export class ReportModule { }
