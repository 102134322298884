import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, catchError, map, of, tap } from "rxjs";
import { environment } from "../../../environments/environment";
import { ResponseAPI } from "../../domain/models/response.model";
import { TokenService } from "../../presentation/shared/services/token.service";
import { UserPositionDetail } from "src/app/domain/models/user.model";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly URL = environment.API_URL;

  usuarioId: string;
  names: string;
  fatherLastName: string;
  motherLastName: string;
  rol: number;
  imagen: string;
  positions: any[] = [];
  level: any = {};

  countRecibed: number = 0;
  countDerivated: number = 0;
  countArchived: number = 0;

  //  CARGO SELECCIONADO GUARDADO EN EL LOCALSTORAGE
  userPositionDetail: UserPositionDetail;

  public positionDefault$: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );
  public positionSelected$: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService
  ) {}

  signIn(user: any): Observable<any> {
    return this.http.post<ResponseAPI>(`${this.URL}/auth/login`, user).pipe(
      map((res) => {
        this.tokenService.saveToken(res.data.access_token);
        return res;
      }),
      catchError((err) => of(false))
    );
  }

  checkToken(): Observable<any> {
    return this.http.get<ResponseAPI>(`${this.URL}/auth/check_token`).pipe(
      tap((res) => {
        console.log("res.data => ", res.data);
        (this.usuarioId = res.data.user.id), (this.names = res.data.user.names);
        this.fatherLastName = res.data.user.father_last_name;
        this.motherLastName = res.data.user.mother_last_name;
        this.rol = res.data.roles[0].rol_id;
        this.imagen = res.data.user.image_path;
        this.positions = res.data.positions;
        this.getCountNotificacions().subscribe();
      }),
      map((res) => {
        if (!this.tokenService.getUserDetail()) {
          this.tokenService.saveUserDetail(this.positions[0]);
          this.positionDefault$.next(this.positions[0]);
          this.userPositionDetail = this.tokenService.getUserDetail();
        } else {
          this.userPositionDetail = this.tokenService.getUserDetail();
          this.positionSelected$.next(this.userPositionDetail);
          console.log("CARGO SELECCIONADO:", this.userPositionDetail);
          this.getCountNotificacions().subscribe();
        }
        return res;
      }),
      catchError((err) => {
        if (!this.rol)
          Swal.fire(
            "Atención",
            "Usted no tiene un Rol asignado, comuniquese con el administrador del sistema",
            "warning"
          );
        if (this.positions.length == 0)
          Swal.fire(
            "Atención",
            "Usted no tiene un Cargo asignado, comuniquese con el administrador del sistema",
            "warning"
          );
        if (err.status == 401) {
          this.router.navigate(["/login"]);
        }
        return of(false);
      })
    );
  }

  logout() {
    this.tokenService.removeToken();
    this.tokenService.removeUserPosition();
    this.router.navigate(["/login"]);
  }

  getCountNotificacions(): Observable<any> {
    return this.http
      .get<ResponseAPI>(`${this.URL}/derivation/count-recibed`)
      .pipe(
        map((res) => {
          this.countRecibed = res["recibed"];
          this.countDerivated = res["derivated"];
          this.countArchived = res["archived"];
        }),
        catchError((err) => {
          console.log("error section :: ", err);
          return err;
        })
      );
  }
}
