import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/data/auth/auth.service';
import { UserPositionDetail } from 'src/app/domain/models/user.model';
import { TokenService } from 'src/app/presentation/shared/services/token.service';

@Component({
  selector: 'app-position-type',
  templateUrl: './position-type.component.html',
  styleUrls: ['./position-type.component.scss']
})
export class PositionTypeComponent implements OnInit {

  positionId: number;
  cargos: any[] = [];

  constructor(
    private authService: AuthService,
    private tokenService: TokenService
  ){  }

  ngOnInit(): void {
    
    this.cargos = this.authService.positions;
    this.positionId = this.authService.userPositionDetail.positionTypeId;

  }

  selectPositionType( cargo: any ){

    this.positionId = cargo.position_type.id;
    this.tokenService.saveUserDetail( cargo );
    this.authService.positionSelected$.next( this.tokenService.getUserDetail() );

  }

}
