import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard/dashboard.module').then( (m) => m.DashboardModule )
  },
  {
    path: 'document',
    loadChildren: () => import('../../modules/document/document.module').then( (m) => m.DocumentModule )
  },
  {
    path: 'follow-ups',
    loadChildren: () => import('../../modules/follow/follow.module').then( (m) => m.FollowModule ) 
  },
  {
    path: 'try-documents',
    loadChildren: () => import('../../modules/document-try/document-try.module').then( (m) => m.DocumentTryModule )
  },
  {
    path: 'request-unarchive',
    loadChildren: () => import('../../modules/request/request.module').then( (m) => m.RequestModule )
  },

  {
    path: 'ventanilla',
    loadChildren: () => import('../../modules/ventanilla/ventanilla.module').then( (m) => m.VentanillaModule )
  },
 
  {
    path: 'administration',
    loadChildren: () => import('../../modules/user/user.module').then( (m) => m.UserModule )
  },
  {
    path: 'flow-tree',
    loadChildren: () => import('../../modules/flow-tree/flow-tree.module').then( (m) => m.FlowTreeModule )
  },
  {
    path: 'charges',
    loadChildren: () => import('../../modules/position/position.module').then( (p) => p.PositionModule )
  },
  {
    path: 'derivation',
    loadChildren: () => import('../../modules/derivation/derivation.module').then( (d) => d.DerivationModule )
  },

  {
    path: 'administrative-court',
    loadChildren: () => import('../../modules/administrative-court/administrative-court.module').then( (m) => m.AdministrativeCourtModule )
  },

  {
    path: 'report-hr',
    loadChildren: () => import('../../modules/report/report.module').then( (m) => m.ReportModule )
  },

  {
    path: 'report-derivation',
    loadChildren: () => import('../../modules/report-derivation/report-derivation.module').then( (m) => m.ReportDerivationModule )
  },

  {
    path: 'report-single-window',
    loadChildren: () => import('../../modules/report/report.module').then( (m) => m.ReportModule )
  },

];
