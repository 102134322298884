import { Injectable } from '@angular/core';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  loading( message: string = 'Verificando...' ){

    Swal.fire({
      title: message,
      html: 'Espere un momento porfavor',
      timerProgressBar: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });

  }

  welcome( message: string = 'Bienvenido' ){

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'success',
      title: message
    })

  }

  alertTimer( message = '', timer = 1500 ){

    Swal.fire({
      icon: 'success',
      title: 'Correcto',
      text: message,
      timer: timer,
      showConfirmButton: false
    });

  }

  alertTimerWarning( message = '', timer = 1500 ){

    Swal.fire({
      icon: 'warning',
      title: 'Opps',
      text: message,
      timer: timer,
      showConfirmButton: false
    });

  }

}
