<div class="container-fluid p-0">
  <!--img src="assets/images/fondo.jpg" alt="logo"-->
  <div class="row">
    <div class="col-12 m-t--100">
      <a class="logo" routerLink="/">
        <img class="img-fluid for-light w-35-per" src="assets/images/logo-min-edu.png" alt="looginpage" />
      </a>
      <div class="login-main">
        <div class="square" style="--i:0;"></div>
        <div class="square" style="--i:1;"></div>
        <div class="square" style="--i:2;"></div>
        <div class="square" style="--i:3;"></div>
        <div class="login-card-logo2">
          <img src="assets/images/logo_chasqui.png" alt="logo">
        </div><br><br>
        <form [formGroup]="form" (ngSubmit)="singIn()" class="login-card-form">
          <div class="form-item">
            <i class="form-item-icon fa fa-user"></i>
              <input formControlName="username" type="text" placeholder="Usuario" id="emailForm" autofocus required>
          </div>
          <div class="form-item">
            <i class="form-item-icon fa fa-lock"></i>
              <input formControlName="password" type="password" placeholder="Contraseña" id="passwordForm" required>
          </div>
          <!--div class="form-item-other">
              <div class="checkbox">
                  <input type="checkbox" id="rememberMeCheckbox" >
                  <label for="rememberMeCheckbox">Recordarme</label>
              </div>
          </div-->
          <button class="z-index-100"><b>Ingresar</b></button>
        </form>
      </div>
    </div>
  </div>
</div>
