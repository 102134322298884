import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportHrComponent } from './pages/report-hr/report-hr.component';
import { SingleWindowComponent } from './pages/single-window/single-window.component';

const routes: Routes = [
  {
    path: '',
    component: ReportHrComponent
  },
  {
    path: 'single-window',
    component: SingleWindowComponent
  },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
