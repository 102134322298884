import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalService } from 'src/app/data/external/external.service';
import { ExternalDocumentService } from 'src/app/data/external_document/external_document.service';
import { ReportPdfComponent } from "../../components/report-pdf/report-pdf.component";

@Component({
  selector: 'app-single-window',
  templateUrl: './single-window.component.html',
  styleUrls: ['./single-window.component.scss']
})
export class SingleWindowComponent implements OnInit{
  types: any[] = [
    {
      id: 1,
      name: 'Unidad'
    },
    {
      id: 2,
      name: 'Funcionario'
    }
  ]
  type_id: number  = null;
  register:any = {};
  external_documents: any[] = [];
  items: any[] = [];

  
  constructor(
    private externalDocumentService: ExternalDocumentService,
    private externalService: ExternalService,
    private modalService: NgbModal,
  ){

  }
  ngOnInit(): void {
    console.log('types',this.types);
    this.getExternalDocuments();
  }


  getExternalDocuments() {
    this.externalDocumentService.findAllByType().subscribe((res) => {
      this.external_documents = res.data;
      console.log('this.external_documents',this.external_documents);
    });
  }

  

  getReport(){
    this.register.date_start_concat = this.register.date_start.day+'-'+this.register.date_start.month+'-'+this.register.date_start.year;
    this.register.date_end_concat = this.register.date_end.day+'-'+this.register.date_end.month+'-'+this.register.date_end.year;
    this.register.date_end_concat_2 = this.register.date_end.year+'-'+this.register.date_end.month+'-'+this.register.date_end.day;
    this.externalService.getAllByType(this.register).subscribe((res) => {
      this.items = res.data;
      console.log('this.external_documents',this.external_documents);
    });
  }

  getPdf(){
    const modalRef = this.modalService.open(ReportPdfComponent,{size: "xl"}); // Reemplaza 'ModalContentComponent' con el nombre de tu componente de modal
      modalRef.componentInstance.datos = this.items;
      modalRef.result.then((result) => {
        if (result) {
        }
      });
  }
}
