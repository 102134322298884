<svg>
  <use href="assets/svg/icon-sprite.svg#tag"></use>
</svg>
Cargos Asignados
<div class="onhover-show-div notification-dropdown">
  <h6 class="f-18 mb-0 dropdown-title">Cargos</h6>
  <ul>
    <li *ngFor="let cargo of cargos" [ngClass]="positionId == cargo.position_type.id ? 'b-l-primary border-4' : ''">
      <p (click)="selectPositionType( cargo )">{{cargo.position_type.name}}</p>
    </li>
  </ul>
</div>