<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header p-3 mb-0">
              <div class="row">
                  <div class="col-12">
                    <h4 class="mb-3"></h4>
                  </div>
              </div>
          </div>
          <div class="card-body">
            <div class="text-right">
                <button class="btn btn-outline-dark btn-pill btn-sm" (click)="printPasswordPdf()"><i class="fa fa-print"></i> Imprimir</button>
              </div>
            <pdf-viewer
            [show-all]="true"
            [show-borders]="true"
            [src]="src"
            [render-text]="true"
            [original-size]="false"
            (after-load-complete)="onLoaded($event)"
            style="width: 100%; height: 400px; margin-left: 0px"
            ></pdf-viewer>
            <div class="container-fluid" style="display: none">
            <div id="htmlData" >
                <table id="my-table">
                    <thead>
                        <tr>
                            <!--td
                                rowspan="2"
                                colspan="1"
                                class="no-line p-r-0"
                                style="padding: 0; margin: 0"
                            >
                                
                            </td-->
                            <td colspan="9" class="no-line-b text-center cell-hight-header">
                                <div class="inline-td m-r--27">
                                    <img
                                        src="assets/images/header.png"
                                        alt=""
                                        class="w-120 m-r-20"
                                    />
                                    <span class="t-7 t-bold-r" style="font-family: Times New Roman;">
                                        SISTEMA DE CORRESPONDENCIA "CHASQUI2"<br>
                                        PETICIONES DE INFORME ESCRITO , PROYECTO DE LEY EN CONSULTA,<br>
                                        DEFENSORIA DEL PUEBLO, MINUTAS DE ISNTRUCCIÓN Y COMUNICACIÓN
                                    </span>
                                </div>
                                
                            </td>
                        </tr>
                    </thead>
                </table>
                
                <table id="my-table" class="p-t-0 p-t-0">
                <tbody>
                    <tr class="row-h-1" style="background-color: rgba(169, 174, 172, 0.763);">
                    <td class="no-line-r w-70 t-bold-r custom-row-heigth">
                        #
                    </td>
                    <td class="no-line-r w-70 t-bold-r custom-row-heigth">
                        Tipo Doc
                    </td>
                    <td class="no-line-r w-70 t-bold-r custom-row-heigth">
                        Cite
                    </td>
                    <td class="no-line-r w-70 t-bold-r custom-row-heigth">
                        Hoja de Ruta
                    </td>
                    <td class="no-line-r w-70 t-bold-r custom-row-heigth">
                        Fecha del Documento
                    </td>
                    <td class="no-line-r w-70 t-bold-r custom-row-heigth">
                        Asunto
                    </td>
                    <td class="no-line-r w-70 w-70 t-bold-r custom-row-heigth">
                        Remitente
                    </td>
                    <td class="no-line-r w-70 w-70 t-bold-r custom-row-heigth">
                        Destinatario
                    </td>
                    <td class="w-70 t-bold-r custom-row-heigth">
                        Empresa/Seccion Remitente
                    </td>
                    </tr>
                    <tr class="row-h-1" *ngFor="let item of datos;let i = index">
                        <td class="no-line-t no-line-r w-70 t-bold-r custom-row-heigth">
                            {{i + 1}}
                        </td>
                        <td class="no-line-t no-line-r w-70 t-bold-r custom-row-heigth">
                            {{ item.external_document.name }}
                        </td>
                        <td class="no-line-t no-line-r w-70 t-bold-r custom-row-heigth">
                            {{ item.cite }}
                        </td>
                        <td class="no-line-t no-line-r w-70 t-bold-r custom-row-heigth">
                            {{ item.roadmap.type }}-{{ item.roadmap.nuri }}/{{ item.roadmap.gestion }}
                        </td>
                        <td class="no-line-t no-line-r w-70 t-bold-r custom-row-heigth">
                            {{ item.created_at | date: 'yyyy/MM/dd'}} {{item.created_at | date: 'HH:mm:ss a'}}
                        </td>
                        <td class="no-line-t no-line-r w-70 t-bold-r custom-row-heigth">
                            {{ item.reference}}
                        </td>
                        <td class="no-line-t no-line-r w-70 w-70 t-bold-r custom-row-heigth">
                            {{ item.external_user.fullname}}
                        </td>
                        <td class="no-line-t no-line-r w-70 w-70 t-bold-r custom-row-heigth">
                            {{ item.positionTo.user.names}} {{ item.positionTo.user.father_last_name}} {{ item.positionTo.user.mother_last_name}}
                        </td>
                        <td class="no-line-t w-70 t-bold-r custom-row-heigth">
                            {{ item.institution.description }}
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
          </div>
            <div class="card-footer text-end">
                <button
                    class="btn btn-light"
                    (click)="closeModal()"
                >
                    Cancelar
                </button>
            </div>
        </div>
      </div>
    </div>
</div>

<!--div class="container-fluid" style="display: none"-->
