import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { ResponseAPI } from '../../domain/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ExternalDocumentService {

  private readonly URL = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }

  findAll(): Observable<ResponseAPI>{
    return this.http.get<ResponseAPI>(`${this.URL}/external_document`);
  }

  findAllByType(): Observable<ResponseAPI>{
    return this.http.get<ResponseAPI>(`${this.URL}/external_document/type`);
  }


}
