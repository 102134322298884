<app-breadcrumb
  [title]="'Reporte de Gestión Documental'"
  [items]="['Reportes', 'Gestión Documental']"
  [active_item]="''"
></app-breadcrumb>

<div class="container-fluid default-list">
  <div class="row">
    <div class="col-md-12" id="bandeja-min">
      <div class="card">
        <div class="card-body mb-0 mt-0 pt-1">
            <div class="row">
                  <div class="col-md-2">
                    <label class="form-label" for="validationCustom01">Tipo:</label>
                    <ng-select  [(ngModel)]="register.type_id"  >
                      <ng-option value="0">TODOS</ng-option>
                      <ng-option *ngFor="let external_document of external_documents" [value]="external_document.id" >{{external_document.name}} </ng-option>
                    </ng-select>
                  </div>
                    <div class="col-md-4">
                        <label class="form-label" for="position"><strong>Fecha Inicio:</strong></label>  
                        <div class="input-group">
                            <input
                                class="form-control"
                                placeholder="yyyy-mm-dd"
                                name="date_start"
                                [(ngModel)]="register.date_start"
                                ngbDatepicker
                                #d="ngbDatepicker"
                                readonly
                                (click)="d.toggle()"
                            />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label" for="position"><strong>Fecha Fin:</strong></label>  
                        <div class="input-group">
                            <input
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            name="date_end"
                            [(ngModel)]="register.date_end"
                            ngbDatepicker
                            #d2="ngbDatepicker"
                            readonly
                            (click)="d2.toggle()"
                            />
                        </div>
                    </div>

                  <div class="col-md-1" *ngIf="register.type_id">
                    <button
                      class="btn btn-pill btn-success btn-add-min btn-ho btn-hover-effect mt-4"
                      (click)="getReport()"
                    >
                      <i class="fa fa-search m-r-5 t-12"></i> Buscar
                    </button>
                  </div>
                  <div class="col-md-1" *ngIf="register.type_id">
                    <button
                      *ngIf="items.length > 0"
                      class="btn btn-pill btn-danger btn-add-min btn-ho btn-hover-effect mt-4"
                      (click)="getPdf()"
                    >
                      <i class="fa fa-file m-r-5 t-12"></i> PDF
                    </button>
                  </div>
            </div>
            <div class=" row mt-4">
              <div class="cod-md-12">
                <table class="table  table-striped table-hover">
                  <thead>
                    <tr style="background: #8c97a8 ; align-content: center;">
                      <th scope="col" class="" >#</th>
                      <th scope="col">Tipo Doc</th>
                      <th scope="col">Cite</th>
                      <th scope="col">Hoja de Ruta</th>
                      <th scope="col">Fecha del Documento</th>
                      <th scope="col">Asunto</th>
                      <th scope="col">Remitente</th>
                      <th scope="col">Destinatario</th>
                      <th scope="col">Empresa/Seccion Remitente</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of items;let i = index">
                      <td>{{i + 1}}</td>
                      <td>{{ item.external_document.name }}</td>
                      <td>{{ item.cite }}</td>
                      <td>{{ item.roadmap.type }}-{{ item.roadmap.nuri }}/{{ item.roadmap.gestion }}</td>
                      <td>{{ item.created_at | date: 'yyyy/MM/dd'}} {{item.created_at | date: 'HH:mm:ss a'}}</td>
                      <td>{{ item.reference}}</td>
                      <td>{{ item.external_user.fullname}} <br> </td>
                      <td>{{ item.positionTo.user.names}} {{ item.positionTo.user.father_last_name}} {{ item.positionTo.user.mother_last_name}}</td>
                      <td>{{ item.institution.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
