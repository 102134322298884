import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { ConsultService } from 'src/app/data/consult/consult.service';
import { FollowService } from 'src/app/data/follow/follow.service';
import { DerivationChild } from 'src/app/domain/models/following.model';
import Swal from "sweetalert2";

@Component({
  selector: 'app-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss']
})
export class ExternalComponent {
  roadmaps: any = [];
  indexFather: number = null;
  roadmapChildren: DerivationChild[] = [];
  indexChild: number = null;
  itemAnexo: boolean = false;
  showListItemsD: boolean = false;
  external: any = {}
  loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private consultService: ConsultService,
  ) {}

  listChildren( index: number, roadmapId: number ) {
    
    this.expandedCard( index );

    this.indexChild = null;
    this.findByRoadmap( roadmapId, index );
    
  }

  findByRoadmap( id: number, index: number ){

    this.loading = true;
    this.consultService.findByRoadmap( id )
      .subscribe( res =>{
        if (res.statusCode < 400) {
          
          this.showListItemsD = true;
          this.indexFather = index;

          this.roadmapChildren = res.data;

          setTimeout(() => {
            const element = document.getElementById(`table-derivation`);
            element.style.animation = "shake 1s";
          }, 300);

        } else {
          Swal.fire("Opps", res.message, "warning");
        }
      })

  }
  
   // EXPANDER EL CARD
   expandedCard( index: number ){
    
    if( this.indexFather != index ){
      
      if( typeof this.indexFather == 'number' ){
        const icon = document.getElementById(`icon-down-${this.indexFather}`);
        icon.classList.remove('icofont-curved-up');
        icon.classList.add('icofont-curved-down');
      }

      this.indexFather = index;

      const icon = document.getElementById(`icon-down-${index}`);
      icon.classList.remove('icofont-curved-down');
      icon.classList.add('icofont-curved-up');
      
    }else{

      this.indexFather = null;
      
      const icon = document.getElementById(`icon-down-${index}`);
      icon.classList.remove('icofont-curved-up');
      icon.classList.add('icofont-curved-up');

    } 

  }

  ngOnInit(): void {
    console.log("load components");
    // this.heroes$ = this.route.paramMap.pipe(
    //   switchMap(params => {
    //     this.selectedId = Number(params.get('id'));
    //     return this.service.getHeroes();
    //   })
    // );
    // this.route.queryParams.pipe(
    //   switchMap( params => {
    //         // this.selectedId = Number(params.get('id'));
    //         console.log(params)
    //         // return this.service.getHeroes();
    //   })
    // )
    this.route.queryParamMap.subscribe( params =>{
      // console.log('params',params)
      // console.log('hash', params.get('hash'))
      this.consultService.findRoadmap({
          hash: params.get('hash')
      })
      .subscribe(res=>{
          console.log('res', res)
          let response: any = res
          this.roadmaps = response.derivations 
          this.external = response.external
          console.log('external' ,this.external)
          // console.log( this.roadmaps )
      });
    })
    console.log('route',this.route.queryParams)
    // this.consultService.findRoadmap({
    //     hash: this.activatedRoute.snapshot.paramMap.get("hashed")
    // })
    // .subscribe(res=>{
    //     this.roadmaps = res
    //     console.log( this.roadmaps )
    // });
    
  }
}
